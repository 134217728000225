import React from "react";
import { makeStyles, Backdrop, CircularProgress, Box } from "@material-ui/core";
import svgLogotipo from "../static/Identificador/Logo hotel dorado.svg";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: 10001,
        background: theme.palette.primary.main,
    },
}));

export default function Loader() {
    const classes = useStyles();
    return (
        <div>
            <Backdrop className={classes.backdrop} open={true}>
                <CircularProgress size={300} thickness={3.0} color="secondary" open={true} />
                <Box position="absolute" display="flex" alignItems="center" justifyContent="center">
                    <img alt="" src={svgLogotipo} style={{ width: 200, height: 200 }} />
                </Box>
            </Backdrop>
        </div>
    );
}

const operation = (
    state = {
        process: false,
    },
    action
) => {
    if (action.type === "START_OPERATION") {
        state = {
            ...state,
            process: action.start,
        };
    }
    return state;
};

export default operation;

import React from "react";
import { AppRoutes } from "./router";
import "./App.css";

import { useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import en from "./lang/en.json";
import es from "./lang/es.json";

const App = () => {
    const locale = useSelector((store) => (store.user.lang === undefined ? "es" : store.user.lang));
    const messages = { en, es };

    return (
        <IntlProvider locale={locale} messages={messages[locale]}>
            <AppRoutes />
        </IntlProvider>
    );
};

export default App;

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { history } from "./history";
import React, { lazy, Suspense } from "react";
import Loader from "../components/Loader";

// Views
const Home = lazy(() => import("../views/Home/index.js"));
const Perfil = lazy(() => import("../views/Perfil/index.js"));
const Habitaciones = lazy(() => import("../views/Habitaciones/index.js"));
const PagoHabitaciones = lazy(() => import("../views/Habitaciones/Pagos.js"));
const CabaretParsien = lazy(() => import("../views/CabaretParisien/index.js"));
const PagoCabaret = lazy(() => import("../views/CabaretParisien/Pagos.js"));
const MenuCabaret = lazy(() => import("../views/CabaretParisien/Menu.js"));
const OfertasCabaretParisien = lazy(() => import("../views/CabaretParisien/Ofertas.js"));
const Legales = lazy(() => import("../views/Legales"));
const LegalesRedirect = lazy(() => import("../views/Legales/Redireccionador.js"));
const Promociones = lazy(() => import("../views/Promociones"));
const PagoPromociones = lazy(() => import("../views/Promociones/Pagos.js"));
const Restaurantes = lazy(() => import("../views/Restaurantes"));
const RestaurantesMenu = lazy(() => import("../views/Restaurantes/Menu.js"));
const RestaurantesRedirectMenu = lazy(() => import("../views/Restaurantes/RedirecMenu.js"));
const QuienesSomos = lazy(() => import("../views/Quienes_Somos/index.js"));
const ComentariosNoticias = lazy(() => import("../views/Comentarios_Noticias/index.js"));
const ComentariosNoticiasComentarios = lazy(() => import("../views/Comentarios_Noticias/Comentarios.js"));
const ComentariosNoticiasNoticias = lazy(() => import("../views/Comentarios_Noticias/Noticias.js"));
const ComentariosNoticiasDetallesNoticias = lazy(() => import("../views/Comentarios_Noticias/DetallesNoticia.js"));
const Contactos = lazy(() => import("../views/Contactos/index.js"));
const Historia = lazy(() => import("../views/Historia/index.js"));
const GaleriaImagenes = lazy(() => import("../views/Galeria/index.js"));
const Piscinas = lazy(() => import("../views/Piscinas"));
const PiscinasMenu = lazy(() => import("../views/Piscinas/Menu.js"));
const PiscinasRedirectMenu = lazy(() => import("../views/Piscinas/RedirecMenu.js"));
const Bares = lazy(() => import("../views/Bares"));
const BaresMenu = lazy(() => import("../views/Bares/Menu.js"));
const BaresRedirectMenu = lazy(() => import("../views/Bares/RedirecMenu.js"));

export const AppRoutes = () => (
    <Suspense fallback={<Loader />}>
        <Router history={history}>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/perfil" component={Perfil} />
                <Route exact path="/habitaciones" component={Habitaciones} />
                <Route exact path="/habitaciones/pagos" component={PagoHabitaciones} />
                <Route exact path="/cabaret_parisien" component={CabaretParsien} />
                <Route exact path="/cabaret_parisien/pagos" component={PagoCabaret} />
                <Route exact path="/cabaret_parisien/menu" component={MenuCabaret} />
                <Route exact path="/cabaret_parisien/ofertas" component={OfertasCabaretParisien} />
                <Route exact path="/legales" component={Legales} />
                <Route exact path="/legales/politica_privacidad" component={LegalesRedirect} />
                <Route exact path="/legales/terminos_condiciones" component={LegalesRedirect} />
                <Route exact path="/legales/terminos_condiciones_premium" component={LegalesRedirect} />
                <Route exact path="/promociones" component={Promociones} />
                <Route exact path="/promociones/pagos" component={PagoPromociones} />
                <Route exact path="/restaurantes" component={Restaurantes} />
                <Route exact path="/restaurantes/menu" component={RestaurantesMenu} />
                <Route exact path="/restaurantes/menu/:id_menu_restaurante" component={RestaurantesRedirectMenu} />
                <Route exact path="/quienes_somos" component={QuienesSomos} />
                <Route exact path="/comentarios_noticias" component={ComentariosNoticias} />
                <Route exact path="/comentarios_noticias/comentarios" component={ComentariosNoticiasComentarios} />
                <Route exact path="/comentarios_noticias/noticias" component={ComentariosNoticiasNoticias} />
                <Route exact path="/comentarios_noticias/noticias/:id_noticia" component={ComentariosNoticiasDetallesNoticias} />
                <Route exact path="/contactos" component={Contactos} />
                <Route exact path="/historia" component={Historia} />
                <Route exact path="/galeria_fotos" component={GaleriaImagenes} />
                <Route exact path="/piscinas" component={Piscinas} />
                <Route exact path="/piscinas/menu" component={PiscinasMenu} />
                <Route exact path="/piscinas/menu/:id_menu_piscina" component={PiscinasRedirectMenu} />
                <Route exact path="/bares" component={Bares} />
                <Route exact path="/bares/menu" component={BaresMenu} />
                <Route exact path="/bares/menu/:id_menu_bar" component={BaresRedirectMenu} />
            </Switch>
        </Router>
    </Suspense>
);

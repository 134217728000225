const loginUser = (
    state = {
        username: "",
        accessToken: "",
        lang: "es",
        money: "cup",
        open_drawer: false,
        dialogErrorOpen: false,
        open_drawer_login: false,
        open_drawer_register: false,
        open_drawer_notification: false,
        open_confirmacion_pago: false,
        open_error_pago: false,
        data_reserva_cabaret: [],
        lista_reserva_cabaret: [],
        nacionalidad_seleccionada: "",
        open_modal_nacionalidad: false,
        go_to_form: false,
        typologyFromHome: false,
        type_page_legale: null,
        tasa_cambio_moneda: [],
        data_reserva_habitaciones: [],
        lista_reserva_habitaciones: [],
        lista_carrito_habitaciones: [],
        total_carrito_habitaciones: 0,
        limpiar_carrito_habitaciones: false,
        cancelar_reservacion_habitaciones: false,
        search_restaurante_menu: null,
        data_reserva_promociones: [],
        open_tab_miperfil: 1,
        search_piscina_menu: null,
        search_bars_menu: null,
    },
    action
) => {
    if (action.type === "LOGIN_USER") {
        state = {
            ...state,
            accessToken: action.accessToken,
            username: action.username,
        };
    } else if (action.type === "LOGOUT_USER") {
        state = {
            ...state,
            username: "",
            accessToken: "",
            lang: "es",
            money: "cup",
        };
    } else if (action.type === "LANG_OPERATION") {
        state = { ...state, lang: action.lang };
    } else if (action.type === "MONEY_OPERATION") {
        state = { ...state, money: action.money };
    } else if (action.type === "DRAWER_OPERATION") {
        state = { ...state, open_drawer: action.status };
    } else if (action.type === "OPEN_DIALOG_ERROR") {
        state = { ...state, dialogErrorOpen: action.status };
    } else if (action.type === "DRAWER_OPERATION_LOGIN") {
        state = { ...state, open_drawer_login: action.status };
    } else if (action.type === "DRAWER_OPERATION_REGISTER") {
        state = { ...state, open_drawer_register: action.status };
    } else if (action.type === "DRAWER_OPERATION_NOTIFICACION") {
        state = { ...state, open_drawer_notification: action.status };
    } else if (action.type === "OPEN_DIALOG_CONFIRMAR_PAGO") {
        state = { ...state, open_confirmacion_pago: action.status };
    } else if (action.type === "OPEN_DIALOG_ERROR_PAGO") {
        state = { ...state, open_error_pago: action.status };
    } else if (action.type === "SAVE_RESERVA_CABARET") {
        state = { ...state, data_reserva_cabaret: action.data, lista_reserva_cabaret: action.lista_reserva };
    } else if (action.type === "SELECT_NACIONALIDAD") {
        state = { ...state, nacionalidad_seleccionada: action.data };
    } else if (action.type === "OPEN_DIALOG_NACIONALIDAD") {
        state = { ...state, open_modal_nacionalidad: action.data };
    } else if (action.type === "OPERATION_GO_TO_FORM") {
        state = { ...state, go_to_form: action.data };
    } else if (action.type === "LOAD_TYPOLOGY_FROM_HOME") {
        state = { ...state, typologyFromHome: action.data };
    } else if (action.type === "LOAD_TYPE_PAGE_LEGALES") {
        state = { ...state, type_page_legale: action.data };
    } else if (action.type === "LOAD_EXCHANGE_RATE") {
        state = { ...state, tasa_cambio_moneda: action.data };
    } else if (action.type === "SAVE_CART_HABITACIONES") {
        state = {
            ...state,
            lista_carrito_habitaciones: action.data,
            total_carrito_habitaciones: action.total,
        };
    } else if (action.type === "CLEAR_CART_HABITACIONES") {
        state = {
            ...state,
            limpiar_carrito_habitaciones: action.data,
        };
    } else if (action.type === "SAVE_RESERVA_HABITACIONES") {
        state = {
            ...state,
            data_reserva_habitaciones: action.data,
            lista_reserva_habitaciones: action.lista_reserva,
        };
    } else if (action.type === "CANCEL_RESERVE_HABITACIONES") {
        state = { ...state, cancelar_reservacion_habitaciones: action.data };
    } else if (action.type === "LOAD_SEARCH_RESTAURANT_MENU") {
        state = { ...state, search_restaurante_menu: action.data };
    } else if (action.type === "SAVE_RESERVA_PROMOCIONES") {
        state = { ...state, data_reserva_promociones: action.data };
    } else if (action.type === "LOAD_TAB_MIPERFIL") {
        state = { ...state, open_tab_miperfil: action.data };
    } else if (action.type === "LOAD_SEARCH_POOLS_MENU") {
        state = { ...state, search_piscina_menu: action.data };
    } else if (action.type === "LOAD_SEARCH_BARS_MENU") {
        state = { ...state, search_bars_menu: action.data };
    }
    return state;
};

export default loginUser;

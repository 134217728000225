import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
//import * as serviceWorker from './serviceWorker';
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { esES } from "@material-ui/core/locale";
import "./static/Tipografía/Georgia/georgia bold italic.ttf";
import "./static/Tipografía/Georgia/georgia bold.ttf";
import "./static/Tipografía/Georgia/georgia italic.ttf";
import "./static/Tipografía/Georgia/georgia.ttf";

import "./static/Tipografía/Verdana/verdana.ttf";
import "./static/Tipografía/Verdana/verdana-negrita.ttf";
import "./static/Tipografía/Verdana/verdana-italica.ttf";
import "./static/Tipografía/Verdana/verdana-negrita-cursiva.ttf";

import { QueryClient, QueryClientProvider } from "react-query";

const client = new QueryClient();

const theme = createMuiTheme(esES, {
    overrides: {},
    palette: {
        primary: {
            main: "#182C20",
            dark: "#262626",
            light: "#565656",
            contrastText: "#929292",
        },
        secondary: {
            main: "#F7D00E",
            dark: "#A68A30",
            light: "#DFDFDF",
            contrastText: "#F3F3F3",
        },
    },
});

ReactDOM.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <ThemeProvider theme={theme}>
                <QueryClientProvider client={client}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <App />
                    </MuiPickersUtilsProvider>
                </QueryClientProvider>
            </ThemeProvider>
        </PersistGate>
    </Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
